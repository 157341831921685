import { useState, useCallback, useEffect } from "react"

import debounce from "utils/debounce"
import { useParameter } from "hooks/useParameters"
import _pagination from "utils/pagination"
import helpers from "utils/helpers"
import services from "services"

const useCompany = ({ business_id }) => {
  const [group_id] = useParameter("group_id")
  const [company, setCompany] = useState()
  const [pagination, setPagination] = useState({})
  const [searchBy, setSearchBy] = useState("users")
  const [search, setSearch] = useState()
  const [redistribution, setRedistribution] = useState({
    active: false,
    userUUID: null,
    businessId: null,
  })

  const handleFetch = useCallback((props) => {
    setCompany({
      group_name: props?.group_name,
      header: props?.header,
      data: props?.paginated_result.data,
      clients: !!props?.header,
    })
    setPagination({
      ...props?.paginated_result.pagination,
      total_pages: _pagination.getTotalPages(
        props?.paginated_result.pagination
      ),
    })
  }, [])

  const fetchCompany = useCallback(
    (props) => {
      services.bases.management
        .company({
          business_id,
          group_id,
          page: props?.page,
          hideLoader: props?.hideLoader,
          search,
          searchBy,
        })
        .then(handleFetch)
    },
    [business_id, group_id, search, searchBy, handleFetch]
  )

  const onSelectFilterBy = (searchBy) => {
    setSearchBy(searchBy)
  }

  const onSearchBy = debounce((target) => {
    setSearch(helpers.isSearchable(target?.value))
  }, 1000)

  const onCloseRedistribution = async () => {
    setRedistribution({ active: false, userUUID: null })
    fetchCompany()
  }

  const onRedistribution = (user) => {
    setRedistribution({
      active: true,
      userUUID: user?.user_uuid,
      businessId: business_id,
    })
  }

  useEffect(() => {
    fetchCompany()
  }, [fetchCompany])

  return {
    company,
    pagination,
    search,
    redistribution: {
      ...redistribution,
      onClose: onCloseRedistribution,
      onRedistribution,
    },
    onSelectFilterBy,
    onSearchBy,
    refresh: fetchCompany,
  }
}

export default useCompany
