import If from "components/If"
import TableFixed, { Cell, Row } from "components/TableFixed"
import Icon from "components/Icon"
import { Ballon, BallonItem } from "components/Ballon"
import Can from "components/Can"
import EmptyState from "components/EmptyState"
import Card from "components/Card"

import classnames from "classnames"
import helpers from "utils/helpers"
import { getCurrentLink } from "./utils"

import "./Users.styl"

const Users = ({ company, onRedistribution, links, business }) => (
  <If condition={company?.clients}>
    <If
      condition={company?.data?.length}
      renderIf={
        <TableFixed className="management-users-table" data-test="users-mode">
          <Row>
            {company?.header?.map((item, index) => (
              <Cell header sticky={!index} key={index}>
                {item}
              </Cell>
            ))}
            <Cell header sticky={false}>
              Ações
            </Cell>
          </Row>
          {company?.data?.map((user, index) => (
            <Row key={user?.user_uuid}>
              <Cell
                sticky
                status={helpers.getStatus(user?.active, user?.absent)}
                to={getCurrentLink({ user, links })}
              >
                {user?.user_name}
              </Cell>
              {user?.leads_count?.map((lead, index) => (
                <Cell key={index}>{lead?.count}</Cell>
              ))}
              <Cell className="management-users-table__actions">
                <Icon className="management-users-table__dots" name="dots" />
                <Ballon
                  className={classnames("management-users-table__ballon", {
                    "management-users-table__ballon--last": index + 1 >= 8,
                  })}
                >
                  <BallonItem icon="spots" to={getCurrentLink({ user, links })}>
                    Ver Board
                  </BallonItem>
                  <If condition={user?.user_uuid}>
                    <Can
                      business={business.business_permission}
                      do="redistribution"
                    >
                      <BallonItem
                        icon="reasing"
                        onClick={() => onRedistribution(user)}
                      >
                        Redistribuir
                      </BallonItem>
                    </Can>
                  </If>
                </Ballon>
              </Cell>
            </Row>
          ))}
        </TableFixed>
      }
      renderElse={
        <Card className="management-users-table__card">
          <EmptyState className="management-users-table__empty" />
        </Card>
      }
    />
  </If>
)

export default Users
