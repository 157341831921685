import Can from "components/Can"
import PageHeader from "components/PageHeader"
import Pagination from "components/Pagination"
import Redistribution from "components/Redistribution"
import Clients from "./components/Clients"
import GenerateBase from "./components/GenerateBase"
import Filters from "./components/Filters"
import Users from "./components/Users"

import useCompany from "./hooks"

import "./Company.styl"

const Company = ({ pageContext: { business, links } }) => {
  const {
    company,
    pagination,
    redistribution,
    search,
    onSelectFilterBy,
    onSearchBy,
    refresh,
  } = useCompany(business)

  return (
    <Can
      profile={["admin", "master"]}
      see={["it", "it"]}
      otherwise={{ goTo: ROUTES.home.path }}
    >
      <div className="company">
        <PageHeader
          className="company__header"
          title={search || "Clientes Potenciais"}
          subtitle={
            search ? "Resultado da busca de clientes para" : company?.group_name
          }
          reverse={search}
        >
          <Filters onSelectFilterBy={onSelectFilterBy} onSearchBy={onSearchBy}>
            <GenerateBase business={business} />
          </Filters>
        </PageHeader>
        <Users
          company={company}
          links={links}
          business={business}
          onRedistribution={redistribution.onRedistribution}
        />
        <Clients
          company={company}
          pagination={pagination}
          business={business}
        />
        <Pagination
          currentPage={pagination.current_page}
          pages={pagination.total_pages}
          onChange={refresh}
        />
        <Redistribution {...redistribution} />
      </div>
    </Can>
  )
}

export default Company
