import Button from "components/Button"
import ModalDialog from "components/ModalDialog"
import Can from "components/Can"

import useGenerateBase from "./hooks"

import "./GenerateBase.styl"

const GenerateBase = ({ business }) => {
  const { dialog, description, onConfirm, onGenerateBase } =
    useGenerateBase(business)

  return (
    <Can business={business.business_permission} do="download">
      <Button
        className="company-generate-base__button"
        variant="group"
        icon="download"
        onClick={onGenerateBase}
      >
        Gerar base de leads
      </Button>
      <ModalDialog
        opened={dialog}
        customConfirm="Fechar"
        description={description}
        onConfirm={onConfirm}
        data-test="modal-dialog"
      />
    </Can>
  )
}

export default GenerateBase
