import Form from "components/Forms/Form"
import SearchBy from "components/SearchBy"
import Input from "components/Forms/Input"

import { options } from "./constants"

import "./Filters.styl"

const Filters = ({ onSelectFilterBy, onSearchBy, children }) => {
  return (
    <div className="company-filters" data-test="filters">
      <Form className="company-filters__form">
        <SearchBy
          className="company-filters__search-by"
          options={options}
          initialSearchBy="users"
          onSelect={onSelectFilterBy}
        />
        <Input
          name="search"
          icon="search"
          margin="false"
          variant="small"
          onChange={(e) => onSearchBy(e?.target)}
          placeholder="Buscar por nome"
          className="company-filters__search"
        />
      </Form>
      {children}
    </div>
  )
}

export default Filters
