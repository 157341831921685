import classnames from "classnames"
import Icon from "components/Icon"
import If from "components/If"
import { Link } from "gatsby"

import "./Ballon.styl"

export const BallonItem = ({ children, to, icon, ...props }) => {
  return (
    <If
      condition={to}
      renderIf={
        <Link to={to} className="ballon__item" {...props}>
          <li>
            <If condition={icon}>
              <Icon className="ballon__icon" name={icon} />
            </If>
            {children}
          </li>
        </Link>
      }
      renderElse={
        <li className="ballon__item" {...props}>
          <If condition={icon}>
            <Icon className="ballon__icon" name={icon} />
          </If>
          {children}
        </li>
      }
    />
  )
}

export const Ballon = ({ position, children, className, ...props }) => {
  return (
    <ul
      className={classnames("ballon", {
        [className]: className,
        [`ballon--${position}`]: position,
      })}
      {...props}
    >
      {children}
    </ul>
  )
}
