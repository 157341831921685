import { useState } from "react"

const useClients = () => {
  const [modal, setModal] = useState({})

  const openModal = ({ lead_uuid }) => setModal({ active: true, lead_uuid })

  const closeModal = () => setModal({ active: false })

  return { modal: { ...modal, openModal, closeModal } }
}

export default useClients
