import { useEffect, useState, useCallback } from "react"
import helpers from "utils/helpers"

const useSearchBy = ({ options, initialSearchBy, onSelect }) => {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState()

  const onToggleOpen = () => {
    setOpen(!open)
  }

  const onSelectOption = (id) => {
    const getSelected = helpers.getItemById(options, id)
    setSelected(getSelected?.name)
    onSelect(id)
  }

  const handleSelected = useCallback(() => {
    const getInitialSearchBy = helpers.getItemById(options, initialSearchBy)

    setSelected(getInitialSearchBy?.name)
  }, [initialSearchBy, options])

  useEffect(() => {
    handleSelected()
  }, [handleSelected])

  return {
    onToggleOpen,
    onSelectOption,
    open,
    selected,
  }
}

export default useSearchBy
