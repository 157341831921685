import Card from "components/Card"
import EmptyState from "components/EmptyState"
import LeadCard from "components/LeadCard"
import LeadModal from "components/LeadModal"
import If from "components/If"

import useClients from "./hooks"

import "./Clients.styl"

const Clients = ({ company, pagination, business }) => {
  const { modal } = useClients()

  return (
    <If condition={!company?.clients}>
      <Card
        className="company-clients"
        data-test="clients-mode"
        title={
          pagination?.total_records && `${pagination?.total_records} clientes`
        }
      >
        <If
          condition={pagination?.total_records}
          renderIf={
            <ul className="company-clients__leads">
              {company?.data?.map((lead, index) => (
                <li className="company-clients__lead" key={index}>
                  <LeadCard
                    statusColor={lead?.status_color}
                    status={lead?.lead_status}
                    name={lead?.lead_name}
                    phone={lead?.phone_number}
                    email={lead?.email}
                    userOwner={lead?.user_owner_name}
                    createdAt={lead?.format_created_at}
                    onClick={() =>
                      modal.openModal({ lead_uuid: lead.lead_uuid })
                    }
                  />
                </li>
              ))}
            </ul>
          }
          renderElse={<EmptyState className="company-clients__empty" />}
        />
      </Card>
      <LeadModal
        active={modal.active}
        leadUUID={modal.lead_uuid}
        business={business}
        onClose={modal.closeModal}
      />
    </If>
  )
}

export default Clients
