import { useCallback, useState } from "react"
import { useParameter } from "hooks/useParameters"
import services from "services"

const useGenerateBase = ({ business_id }) => {
  const [group_id] = useParameter("group_id")
  const [dialog, setDialog] = useState(false)
  const [description, setDescription] = useState("")

  const handleGenerateDownloadBase = ({ message, error }) => {
    if (!error) {
      setDescription(message)
      setDialog(true)
    }
  }

  const onGenerateBase = useCallback(() => {
    services.bases
      .download({ group_id, business_id })
      .then(handleGenerateDownloadBase)
  }, [business_id, group_id])

  const onConfirm = () => {
    setDialog(false)
    setDescription("")
  }

  return {
    onGenerateBase,
    onConfirm,
    dialog,
    description,
  }
}

export default useGenerateBase
