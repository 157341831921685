import Icon from "components/Icon"

import classnames from "classnames"
import useSearchBy from "./hooks"

import "./SearchBy.styl"

const SearchBy = ({ className, options, initialSearchBy, onSelect }) => {
  const { onToggleOpen, onSelectOption, open, selected } = useSearchBy({
    options,
    initialSearchBy,
    onSelect,
  })

  return (
    <div className={classnames("search-by", className)}>
      <div className="search-by__dropdown" onClick={onToggleOpen}>
        <p className="search-by__title">Buscar por</p>
        <span className="search-by__selected">{selected}</span>
        <Icon
          className={classnames("search-by__icon", {
            "search-by__icon--active": open,
          })}
          name="arrow-down"
        />
        <ul
          className={classnames("search-by__options", {
            "search-by__options--active": open,
          })}
        >
          {options.map((option) => (
            <li
              key={option?.id}
              className="search-by__option"
              onClick={() => onSelectOption(option?.id)}
            >
              {option?.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default SearchBy
